* {
  font-family: system-ui;
}

:root {
  --background-panel: hsla(0, 0%, 100%, 0.5);
}

body {
  margin: 0;

  touch-action: none;

  // TODO move this down the tree?
  user-select: none;
}

.canvas-container {
  width: 100dvw;
  height: 100dvh;

  & canvas {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.toggles {
  position: fixed;
  width: 100dvw;
  display: flex;
  justify-content: space-between;
  background: var(--background-panel);

  fieldset {
    display: flex;
    flex-direction: column;
  }
}

.info {
  position: fixed;
  padding: 0.5em;
  bottom: env(safe-area-inset-bottom);
  pointer-events: none;
  font-size: 1vmax;
}

.camera {
  display: flex;
  flex-direction: column;

  & > div {
    padding-left: 1em;
  }
}

.controls {
  font-size: 1vmax;
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  right: 0;
  padding: 0.5em;

  label {
    display: flex;
    align-items: center;
  }
}
